//印刷用のCSS
@page { size: A4 }           /* A4タテ */
body {
    zoom: 90%;
    -webkit-print-color-adjust: exact;
}
body.is-notzoom {
    zoom: 100%;
}

.l-header {
    // display: none;
}
.l-footer {
    display: none;
}
.k-device-switch{
    display: none;
}
.l-wrapper,
.c-unit__box{
    width: 1180px;
}
.l-wrapper{
    .safari &{
        transform-origin: center top;
        transform: scale(0.99);
    }
}
.k-product-detail-main{
    width: auto;
    max-width: 100%;
    .k-product-detail-main__left{
        width: auto;
    }
    .k-product-detail-main__right{
        width: auto;
        .k-product-detail-bottom{
            table{width: 98%;}
        }
    }
}

//Firefox 対策
body.firefox {
    .k-product-detail-main {
        display: table;
    }
    .k-product-detail-main__left {
        display: table-cell;
    }
    .k-product-detail-main__right {
        display: table-cell;
        vertical-align: top;
    }
    // 詳細画面
    .k-panel-01__top {
        .k-panel-01__top__left {
            display: block;
        }
        .k-panel-01__top__right {
            display: block;
        }
    }

}

//印刷用ユーティリティクラス

.u-do-not-print{
    display:none !important;
}
.u-print{
    display:block !important;
}

//モーダル
body.is-modalOpen {
    .l-wrapper,
    .c-modal__shade {
        display: none;
    }
    // sarafiで印刷時に消えるのを回避
    width: 100vw;
    min-height: 26cm;

}
// IE対応
body.is-modalOpen {
    position: static;
}

.c-modal {
    width: auto;
    top: 0;
    bottom: 0;
    min-width: 100vw;
    height: 100%;
    min-height: 100vh;
    overflow: visible !important;
    .safari &{
        min-width: 18cm;
        min-height: 26cm;
    }
}
.ie .c-modal__inner {
    padding-top: 82px !important;
    transform-origin: left top;
    transform: scale(1.1);
    font-size: 12px;
}
.r-heading__inner {
    width: auto;
    margin: 0;
    padding: 0 40px;
}

// OB 汎用テーブル 
.p-table--type-12 {
    // 廃番商品 
    .tr-disabled td {
      color: #7f8181;
      .ie &{
        &:after {
          content: none;
        }
    }
    }
}
//横スクロールするテーブル Firefox対策
.firefox [data-event="table-scroll"]{
    display: table;
    .fixedTableWrap,
    .scrollTableWrap{
        float: none !important;
        display: table-cell;
        vertical-align: top;
    }
}

//モーダル内の商品画像部分
.k-product-img--modal {
    .k-product-img__thumb-main {
        width: 52%;
        height: 52%;
        // height: auto;
        margin: 0;
        padding: 0;
    }
    .k-product-img__thumb-small {
        position: relative;
        width: 46%;
        margin: 0 -10px 0 0;
        padding: 0;
        .k-product-img__thumb-item {
            width: auto;
            width: 16%;
            height: auto;
            img {
                width: 100%;
            }
        }
    }
}

.k-product-detail-info__left {
    min-width: 48%;
}
.k-product-detail-info__right {
    min-width: 52%;
    .modal-stockmap-inner {
        width: 100%;
    }
    .modal-stockmap-img img {
        width: 100%;
    }
}

//在庫照会（地図から探す）
.c-modal__inner.k-product-detail-info {
    padding-top: 108px !important;
    transform: scale(0.8);
    transform-origin: left top;
    .ie & {
        padding-top: 82px !important;
        transform: scale(1.1);
        font-size: 12px;
    }
}
.modal-stockmap-text{
    .ie & {
        margin-top: 10px;
        margin-left: 10px;
        font-size: 12px;
    }
}

//商品選択
.print-OB3110W090{
    .ie &{
        table-layout: fixed;
        word-break: break-all;
        th:nth-of-type(2){
            width: 140px !important;
        }
        th:nth-of-type(5){
            width: 70px !important;
        }
        .u-nowrap{
            white-space: normal !important;
        }
    }
}

//商品検索
.r-search-form{
    .ie &{
        margin-bottom: 0;
        .p-textbox.u-w400{
            width: 100% !important;
            margin-right: 0 !important;
            margin-bottom: 10px !important;
        }
    }
}
.print-OB3110W110{
    .ie &{
        table-layout: fixed;
        word-break: break-all;
        th:nth-of-type(1){
            width: 140px !important;
        }
        th:nth-of-type(4){
            width: 70px !important;
        }
        .u-nowrap{
            white-space: normal !important;
        }
    }
}
//価格・在庫照会結果
body:not(.safari) .c-modal__inner .u-plr20.u-ptb20 .p-table--type-12{
    transform: scale(0.86);
    transform-origin: left top;
}
.ie .c-modal__inner .price-stock-block-title__head{
    font-size: 16px;
}
.ie .c-modal__inner .u-plr20.u-ptb20 .p-table--type-12{
    th{
        padding: 5px;
        white-space: normal !important;
        font-size: 12px;
    }
    th.u-w95{
        width: auto !important;
    }
    th.u-w120{
        width: auto !important;
    }
    th.u-w220{
        width: auto !important;
    }
    th.u-w400{
        width: auto !important;
    }
    td{
        padding: 5px;
        word-break: break-all;
        font-size: 12px;
    }
    .u-nowrap{
        white-space: normal !important;
    }
}
.situation-sum{
    white-space: normal !important;
    .u-float-left{
        width: auto;
        margin-right: 80px;
        .a-icon--shop,
        .a-icon--shop_gray {
            width: auto !important;
        }
    }
    .ie &{
        .u-float-left{
            width: auto;
            margin-right: 60px;
            .u-font14 {
                font-size: 12px !important;
            }
        }
    }
}
.ie .p-table--type__situation .k-title--02{
    font-size: 14px;
}
.ie .price-stock-block__panel-table{
    .u-font16 {
        font-size: 14px !important;
    }
}

.c-modal__inner .u-plr20.u-ptb20 .p-table--type-12{
  transform: scale(0.86);
  transform-origin: left top;
    .ie & {
      transform: scale(1);
    }
}

//スペック比較一覧画面
.k-table-comparison{
    overflow: hidden;
}

//修理品画像一覧
.c-grid--int40 > .c-grid__col{
    .js-attachmentfile{
        display: flex;
        width: 100%;
        padding-right: 40px;
        .ie &{
            display: block;
            overflow: hidden;
            padding-right: 0;
        }
    }
    .js-attachmentfile__txt{
        width: auto !important;
        max-width: 155px;
        .ie &{
            float: left;
            max-width: 105px;
            margin-right: 0 !important;
        }
    }
    .js-attachmentfile__btn{
        width: 50px;
        white-space: nowrap;
        .ie &{
            width: 48px;
            float: right;
        }
    }
    .js-attachmentfile__file{
        display: none;
    }
}

//ユーザーの選択
.print-OB3110S170-001{
    th{
        width: 180px !important;
    }
    .ie &,
    .edge &{
        .p-textbox{width: 340px !important;}
    }
}
.print-OB3110S170-002{
    button{
        white-space: nowrap;
    }
}

//直送先情報
.ie .print-OB3110S210{
    transform-origin: left top;
    transform: scale(1.13);
}

//来店情報
.ie .print-OB3110S220{
    transform-origin: left top;
    transform: scale(1.13);
}

//メーカー検索
.print-OB3110S050{
    th{width: 180px !important;}
    .ie &,
    .edge &{
        .p-textbox{width: 290px !important;}
    }
}

//業務画面用商品詳細
.print-OB3010W010{
    .r-heading--lv1{
        position: relative;
        .ie &{
            width: 110%;
        }
    }
    .c-modal__inner {
        position: relative;
        padding-top: 40px !important;
        transform-origin: left top;
        transform: scale(0.8);
        >.u-right{
            position: absolute;
            width: 125%;
            padding: 10px 20px;
            top: 0;
            right: 0;
            left: 0;
        }
        .safari &{
            padding-top: 0 !important;
            transform: scale(1);
            >.u-right{
                width: 100%;
                padding: 10px 0;
                position: relative;
            }
            .k-product-detail-main{
                transform-origin: left top;
                transform: scale(1.05);
            }
        }
        .ie &{
            transform: scale(0.7);
            >.u-right{
                width: 155%;
            }
        }
        .edge &{
            transform: scale(0.73);
            >.u-right{
                width: 137%;
            }
        }
        .firefox &{
            .k-product-detail-main{
                display: table;
                width: 100%;
            }
            .k-product-detail-main-modal__right,
            .k-product-detail-main-modal__left{
                display: table-cell;
                vertical-align: top;
            }
        }
    }
}
//キャンペーン
.k-campaign {
    &__date {
        //Safari対応
        .mac.safari &{
            letter-spacing: -0.08em;
        }
    }
}

//ランキング
.c-grid.c-grid--5col.c-grid--int35{
    display: block;
    &:after{
        content: "";
        clear: both;
        display: table;
    }
    .c-grid__col{
        float: left;
    }
}

//カルーセル
.edge {
    .k-slide--01,
    .k-slide--02,
    .k-slide--03{
        .slick-list{
            overflow: visible;
        }
    }
    .k-slide--01{
        .slick-list{
            margin-left: -97px;
        }
    }
    .k-slide--02{
        .slick-list{
            margin-left: -135px;
        }
    }
    .k-slide--03{
        .slick-list{
            margin-left: -100px;
        }
    }
}

//PCで表示しないクラスは印刷しない
.u-hidden-pc {
    display: none !important;
}
