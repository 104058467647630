@charset "UTF-8";
@page {
    size: A4;
}

/* A4タテ */
body {
    zoom: 90%;
    -webkit-print-color-adjust: exact;
}

body.is-notzoom {
    zoom: 100%;
}

.l-footer {
    display: none;
}

.k-device-switch {
    display: none;
}

.l-wrapper,
.c-unit__box {
    width: 1180px;
}

.safari .l-wrapper {
    transform-origin: center top;
    transform: scale(0.99);
}

.k-product-detail-main {
    width: auto;
    max-width: 100%;
}

.k-product-detail-main .k-product-detail-main__left {
    width: auto;
}

.k-product-detail-main .k-product-detail-main__right {
    width: auto;
}

.k-product-detail-main .k-product-detail-main__right .k-product-detail-bottom table {
    width: 98%;
}

body.firefox .k-product-detail-main {
    display: table;
}

body.firefox .k-product-detail-main__left {
    display: table-cell;
}

body.firefox .k-product-detail-main__right {
    display: table-cell;
    vertical-align: top;
}

body.firefox .k-panel-01__top .k-panel-01__top__left {
    display: block;
}

body.firefox .k-panel-01__top .k-panel-01__top__right {
    display: block;
}

.u-do-not-print {
    display: none !important;
}

.u-print {
    display: block !important;
}

body.is-modalOpen {
    width: 100vw;
    min-height: 26cm;
}

body.is-modalOpen .l-wrapper,
body.is-modalOpen .c-modal__shade {
    display: none;
}

body.is-modalOpen {
    position: static;
}

.c-modal {
    width: auto;
    top: 0;
    bottom: 0;
    min-width: 100vw;
    height: 100%;
    min-height: 100vh;
    overflow: visible !important;
}

.safari .c-modal {
    min-width: 18cm;
    min-height: 26cm;
}

.ie .c-modal__inner {
    padding-top: 82px !important;
    transform-origin: left top;
    transform: scale(1.1);
    font-size: 12px;
}

.r-heading__inner {
    width: auto;
    margin: 0;
    padding: 0 40px;
}

.p-table--type-12 .tr-disabled td {
    color: #7f8181;
}

.ie .p-table--type-12 .tr-disabled td:after {
    content: none;
}

.firefox [data-event="table-scroll"] {
    display: table;
}

.firefox [data-event="table-scroll"] .fixedTableWrap,
.firefox [data-event="table-scroll"] .scrollTableWrap {
    float: none !important;
    display: table-cell;
    vertical-align: top;
}

.k-product-img--modal .k-product-img__thumb-main {
    width: 52%;
    height: 52%;
    margin: 0;
    padding: 0;
}

.k-product-img--modal .k-product-img__thumb-small {
    position: relative;
    width: 46%;
    margin: 0 -10px 0 0;
    padding: 0;
}

.k-product-img--modal .k-product-img__thumb-small .k-product-img__thumb-item {
    width: auto;
    width: 16%;
    height: auto;
}

.k-product-img--modal .k-product-img__thumb-small .k-product-img__thumb-item img {
    width: 100%;
}

.k-product-detail-info__left {
    min-width: 48%;
}

.k-product-detail-info__right {
    min-width: 52%;
}

.k-product-detail-info__right .modal-stockmap-inner {
    width: 100%;
}

.k-product-detail-info__right .modal-stockmap-img img {
    width: 100%;
}

.c-modal__inner.k-product-detail-info {
    padding-top: 108px !important;
    transform: scale(0.8);
    transform-origin: left top;
}

.ie .c-modal__inner.k-product-detail-info {
    padding-top: 82px !important;
    transform: scale(1.1);
    font-size: 12px;
}

.ie .modal-stockmap-text {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 12px;
}

.ie .print-OB3110W090 {
    table-layout: fixed;
    word-break: break-all;
}

.ie .print-OB3110W090 th:nth-of-type(2) {
    width: 140px !important;
}

.ie .print-OB3110W090 th:nth-of-type(5) {
    width: 70px !important;
}

.ie .print-OB3110W090 .u-nowrap {
    white-space: normal !important;
}

.ie .r-search-form {
    margin-bottom: 0;
}

.ie .r-search-form .p-textbox.u-w400 {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
}

.ie .print-OB3110W110 {
    table-layout: fixed;
    word-break: break-all;
}

.ie .print-OB3110W110 th:nth-of-type(1) {
    width: 140px !important;
}

.ie .print-OB3110W110 th:nth-of-type(4) {
    width: 70px !important;
}

.ie .print-OB3110W110 .u-nowrap {
    white-space: normal !important;
}

body:not(.safari) .c-modal__inner .u-plr20.u-ptb20 .p-table--type-12 {
    transform: scale(0.86);
    transform-origin: left top;
}

.ie .c-modal__inner .price-stock-block-title__head {
    font-size: 16px;
}

.ie .c-modal__inner .u-plr20.u-ptb20 .p-table--type-12 th {
    padding: 5px;
    white-space: normal !important;
    font-size: 12px;
}

.ie .c-modal__inner .u-plr20.u-ptb20 .p-table--type-12 th.u-w95 {
    width: auto !important;
}

.ie .c-modal__inner .u-plr20.u-ptb20 .p-table--type-12 th.u-w120 {
    width: auto !important;
}

.ie .c-modal__inner .u-plr20.u-ptb20 .p-table--type-12 th.u-w220 {
    width: auto !important;
}

.ie .c-modal__inner .u-plr20.u-ptb20 .p-table--type-12 th.u-w400 {
    width: auto !important;
}

.ie .c-modal__inner .u-plr20.u-ptb20 .p-table--type-12 td {
    padding: 5px;
    word-break: break-all;
    font-size: 12px;
}

.ie .c-modal__inner .u-plr20.u-ptb20 .p-table--type-12 .u-nowrap {
    white-space: normal !important;
}

.situation-sum {
    white-space: normal !important;
}

.situation-sum .u-float-left {
    width: auto;
    margin-right: 80px;
}

.situation-sum .u-float-left .a-icon--shop,
.situation-sum .u-float-left .a-icon--shop_gray {
    width: auto !important;
}

.ie .situation-sum .u-float-left {
    width: auto;
    margin-right: 60px;
}

.ie .situation-sum .u-float-left .u-font14 {
    font-size: 12px !important;
}

.ie .p-table--type__situation .k-title--02 {
    font-size: 14px;
}

.ie .price-stock-block__panel-table .u-font16 {
    font-size: 14px !important;
}

.c-modal__inner .u-plr20.u-ptb20 .p-table--type-12 {
    transform: scale(0.86);
    transform-origin: left top;
}

.ie .c-modal__inner .u-plr20.u-ptb20 .p-table--type-12 {
    transform: scale(1);
}

.k-table-comparison {
    overflow: hidden;
}

.c-grid--int40 > .c-grid__col .js-attachmentfile {
    display: flex;
    width: 100%;
    padding-right: 40px;
}

.ie .c-grid--int40 > .c-grid__col .js-attachmentfile {
    display: block;
    overflow: hidden;
    padding-right: 0;
}

.c-grid--int40 > .c-grid__col .js-attachmentfile__txt {
    width: auto !important;
    max-width: 155px;
}

.ie .c-grid--int40 > .c-grid__col .js-attachmentfile__txt {
    float: left;
    max-width: 105px;
    margin-right: 0 !important;
}

.c-grid--int40 > .c-grid__col .js-attachmentfile__btn {
    width: 50px;
    white-space: nowrap;
}

.ie .c-grid--int40 > .c-grid__col .js-attachmentfile__btn {
    width: 48px;
    float: right;
}

.c-grid--int40 > .c-grid__col .js-attachmentfile__file {
    display: none;
}

.print-OB3110S170-001 th {
    width: 180px !important;
}

.ie .print-OB3110S170-001 .p-textbox,
.edge .print-OB3110S170-001 .p-textbox {
    width: 340px !important;
}

.print-OB3110S170-002 button {
    white-space: nowrap;
}

.ie .print-OB3110S210 {
    transform-origin: left top;
    transform: scale(1.13);
}

.ie .print-OB3110S220 {
    transform-origin: left top;
    transform: scale(1.13);
}

.print-OB3110S050 th {
    width: 180px !important;
}

.ie .print-OB3110S050 .p-textbox,
.edge .print-OB3110S050 .p-textbox {
    width: 290px !important;
}

.print-OB3010W010 .r-heading--lv1 {
    position: relative;
}

.ie .print-OB3010W010 .r-heading--lv1 {
    width: 110%;
}

.print-OB3010W010 .c-modal__inner {
    position: relative;
    padding-top: 40px !important;
    transform-origin: left top;
    transform: scale(0.8);
}

.print-OB3010W010 .c-modal__inner > .u-right {
    position: absolute;
    width: 125%;
    padding: 10px 20px;
    top: 0;
    right: 0;
    left: 0;
}

.safari .print-OB3010W010 .c-modal__inner {
    padding-top: 0 !important;
    transform: scale(1);
}

.safari .print-OB3010W010 .c-modal__inner > .u-right {
    width: 100%;
    padding: 10px 0;
    position: relative;
}

.safari .print-OB3010W010 .c-modal__inner .k-product-detail-main {
    transform-origin: left top;
    transform: scale(1.05);
}

.ie .print-OB3010W010 .c-modal__inner {
    transform: scale(0.7);
}

.ie .print-OB3010W010 .c-modal__inner > .u-right {
    width: 155%;
}

.edge .print-OB3010W010 .c-modal__inner {
    transform: scale(0.73);
}

.edge .print-OB3010W010 .c-modal__inner > .u-right {
    width: 137%;
}

.firefox .print-OB3010W010 .c-modal__inner .k-product-detail-main {
    display: table;
    width: 100%;
}

.firefox .print-OB3010W010 .c-modal__inner .k-product-detail-main-modal__right,
.firefox .print-OB3010W010 .c-modal__inner .k-product-detail-main-modal__left {
    display: table-cell;
    vertical-align: top;
}

.mac.safari .k-campaign__date {
    letter-spacing: -0.08em;
}

.c-grid.c-grid--5col.c-grid--int35 {
    display: block;
}

.c-grid.c-grid--5col.c-grid--int35:after {
    content: "";
    clear: both;
    display: table;
}

.c-grid.c-grid--5col.c-grid--int35 .c-grid__col {
    float: left;
}

.edge .k-slide--01 .slick-list,
.edge .k-slide--02 .slick-list,
.edge .k-slide--03 .slick-list {
    overflow: visible;
}

.edge .k-slide--01 .slick-list {
    margin-left: -97px;
}

.edge .k-slide--02 .slick-list {
    margin-left: -135px;
}

.edge .k-slide--03 .slick-list {
    margin-left: -100px;
}

.u-hidden-pc {
    display: none !important;
}
